
.container {
  display: grid;
  grid-template-columns: auto 1fr;
  justify-items: center;
  padding: 0 3.2rem 3.2rem 3.2rem;
  gap: 1.6rem;
}
@media screen and (max-width: 1300px) {
  .container {
    grid-template-columns: 1fr;
    row-gap: 2rem;
  }
}

@media screen and (max-width: 900px) {
  .container {
    padding: 0 1.6rem 1.6rem 1.6rem;
  }
}

@media screen and (max-width: 300px) {
  .container {
    padding: 0 0.8rem 0.8rem 0.8rem;
  }
}

.home-page-spinner{
  color:#278439;
  font-size: 3.6rem;
  align-self: center;
  animation:home-spinner 1s linear infinite;
}

.fixture-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 1rem;
  overflow: hidden;
  color: #f1f3f5;
}

.league-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.league-header {
  padding: 1.2rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  background-color: #2c2c2c;
}

.league-country {
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 20% center;
  border: 1px solid gray;
}

@media screen and (max-width: 600px) {
  .league-country {
    width: 2.4rem;
    height: 2.4rem;
  }
}
@media screen and (max-width: 360px) {
  .league-country {
    width: 1.8rem;
    height: 1.8rem;
  }
}

.league-name {
  font-size: 2.4rem;
  font-weight: 600;
}

@media screen and (max-width: 900px) {
  .league-name {
    font-size: 2rem;
  }
}

@media screen and (max-width: 600px) {
  .league-name {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 360px) {
  .league-name {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 300px) {
  .league-name {
    font-size: 1.2rem;
  }
}

.match-list {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  background-color: #343434;
  padding: 1.6rem 2.4rem;
}
@media screen and (max-width: 1100px) {
  .match-list {
    padding: 1.6rem;
  }
}

@media screen and (max-width: 900px) {
  .match-list {
    gap: 1.6rem;
  }
}
@media screen and (max-width: 600px) {
  .match-list {
    gap: 1.2rem;
  }
}

@media screen and (max-width: 450px) {
  .match-list {
    padding: 1.2rem;
    gap: 0.8rem;
  }
}

@media screen and (max-width: 450px) {
  .match-list {
    padding: 0.8rem;
  }
}

.match-container {
  display: grid;
  grid-template-columns: 3fr 1fr 3fr;
  grid-template-rows: auto 1fr;
  background-color: #3d3d3d;
  border-radius: 1rem;
  transition: all 0.3s;
  cursor: pointer;
}

.match-container:hover {
  box-shadow: 0 0 2px 2px #40c057;
}

.match-header {
  padding-top: 1.6rem;
  grid-row: 1/2;
  grid-column: 2/3;
  justify-self: center;
  align-self: center;
  font-size: 1.2rem;
}

@media screen and (max-width: 600px) {
  .match-header {
    padding-top: 1.2rem;
  }
}
@media screen and (max-width: 360px) {
  .match-header {
    padding-top: 0.8rem;
  }
}

.match-date,
.match-hour {
  font-size: 1.6rem;
  text-align: center;
  font-weight: 600;
}

@media screen and (max-width: 600px) {
  .match-date,
  .match-hour {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 360px) {
  .match-date,
  .match-hour {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 340px) {
  .match-date,
  .match-hour {
    font-size: 1rem;
  }
}

.team-container {
  padding: 1.6rem;
  display: flex;
  align-items: center;
  width: 90%;
  gap: 3.6rem;
  letter-spacing: 1px;
}

@media screen and (max-width: 900px) {
  .team-container {
    gap: 1.6rem;
  }
}

@media screen and (max-width: 550px) {
  .team-container {
    padding: 1.2rem;
    gap: 0.8rem;
  }
}

@media screen and (max-width: 360px) {
  .team-container {
    padding: 0.8rem;
    gap: 0.8rem;
  }
}

.home-team-container {
  grid-row: 2/3;
  grid-column: 1/2;
  justify-self: end;
}

@media screen and (max-width: 1100px) {
  .home-team-container {
    justify-self: start;
  }
}

.score {
  grid-row: 2/3;
  grid-column: 2/3;
  display: flex;
  align-items: center;
  gap: 1.2rem;
  padding: 1.2rem;
  border-radius: 1rem;
  justify-self: center;
  align-self: center;
  font-size: 3.6rem;
  background-color: #454545;
}

@media screen and (max-width: 900px) {
  .score {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 700px) {
  .score {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 600px) {
  .score {
    font-size: 1.8rem;
    gap: 0.6rem;
  }
}

@media screen and (max-width: 550px) {
  .score {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 550px) {
  .score {
    font-size: 1.4rem;
    padding: 0.8rem;
    border-radius: 0.8rem;
  }
}

@media screen and (max-width: 340px) {
  .score {
    font-size: 1.2rem;
    padding: 0.6rem;
    border-radius: 0.6rem;
  }
}

.away-team-container {
  grid-row: 2/3;
  grid-column: 3/4;
  flex-direction: row-reverse;
}

@media screen and (max-width: 1100px) {
  .away-team-container {
    justify-self: end;
  }
}

.team-logo img {
  height: 9.6rem;
}

@media screen and (max-width: 900px) {
  .team-logo img {
    height: 8.4rem;
  }
}

@media screen and (max-width: 750px) {
  .team-logo img {
    height: 6.4rem;
  }
}

@media screen and (max-width: 600px) {
  .team-logo img {
    height: 4.8rem;
  }
}
@media screen and (max-width: 550px) {
  .team-logo img {
    height: 3.2rem;
  }
}
@media screen and (max-width: 400px) {
  .team-logo img {
    height: 1.8rem;
  }
}

@media screen and (max-width: 340px) {
  .team-logo img {
    height: 1.2rem;
  }
}

.team-name {
  font-size: 3rem;
}
@media screen and (max-width: 900px) {
  .team-name {
    font-size: 2.4rem;
  }
}
@media screen and (max-width: 700px) {
  .team-name {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 550px) {
  .team-name {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 450px) {
  .team-name {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 400px) {
  .team-name {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 340px) {
  .team-name {
    font-size: 1rem;
  }
}

.away-team-container .team-name {
  text-align: end;
}

@keyframes home-spinner{
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(360deg);
  }
}
