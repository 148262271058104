.match-page-mode-selector-container {
  margin-top: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.6rem;
}

.match-page-icon {
  color: #f1f3f5;
  width: 4.8rem;
  height: 4.8rem;
  padding: 1.2rem;
  cursor: pointer;
}

.match-page-restricted-icon {
  cursor: not-allowed;
  color: #f83030;
}

.match-page-active-icon {
  color: #2f9e44;
  border-radius: 50%;
  background-color: #3d3d3d;
}

.match-page-outer-container {
  padding: 1.6rem;
}

@media screen and (max-width: 345px) {
  .match-page-outer-container {
    padding: 1.2rem 0.8rem;
  }
}

.match-page-submit-points {
  display: block;
  margin: 1.2rem auto;
  border: none;
  background-color: #237733;
  padding: 2rem;
  max-width: 100rem;
  width: 100%;
  align-self: stretch;
  color: #f3fbf4;
  border-radius: 1rem;
  font-size: 2.4rem;
  transition: all 0.4s;
  box-shadow: none;
  cursor: pointer;
}

.match-page-submit-points:disabled {
  cursor: not-allowed;
}

@media screen and (max-width: 385px) {
  .match-page-submit-points {
    padding: 1.2rem;
    font-size: 1.8rem;
  }
}

.match-page-submit-points:hover {
  background-color: #2f9e44;
  transform: translateY(-0.3rem);
  box-shadow: 0 3px 5px 4px rgba(35, 119, 51, 0.6);
}

.match-page-spinner{
  font-size: 2rem;
  color:"#f1f3f5";
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
