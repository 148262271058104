.container-outer {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-inner {
  background-color: #232323;
  padding: 6rem 3rem;
  border-radius: 1rem;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
}

.input-container {
  padding: 1px;
  background-color: transparent;
  border-radius: 1rem;
  position: relative;
  z-index: 2;
  overflow: hidden;
}

.input-container:focus-within::after {
  content: "";
  display: block;
  width: 27rem;
  height: 27rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transform-origin: top left;
  background-color: transparent;
  background-color: #2f9e44;
  animation: rotate 2s linear infinite;
  z-index: -1;
  box-shadow: 0 0 4px 4px rgba(62, 185, 85, 0.8);
}

.input {
  display: block;
  border: none;
  width: 25rem;
  font-size: 2.4rem;
  outline: none;
  border-radius: 1rem;
  padding: 1.2rem 1.6rem;
  background-color: #343434;
  color: #f3fbf4;
  z-index: 3;
}

.input::placeholder {
  color: #f3fbf4;
}

.submit {
  width: 100%;
  margin-top: 0.2rem;
  border: none;
  background-color: #237733;
  padding: 2rem;
  align-self: stretch;
  color: #f3fbf4;
  border-radius: 1rem;
  font-size: 2.4rem;
  transition: all 0.4s;
  box-shadow: none;
  cursor: pointer;
}
.submit:disabled {
  cursor: not-allowed;
}
.submit:hover {
  background-color: #2f9e44;
  transform: translateY(-0.3rem);
  box-shadow: 0 3px 5px 4px rgba(35, 119, 51, 0.6);
}

.active-login-spinner {
  animation: spin 1s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
