.field-player-container {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
}

@media screen and (max-width: 820px) {
  .field-player-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
}

.match-page-home-team-container,
.match-page-away-team-container {
  width: 100%;
}

.match-page-home-team-container {
  grid-column: 1/2;
  grid-row: 1/2;
}

.match-page-away-team-container {
  grid-column: 2/3;
  grid-row: 1/2;
}

@media screen and (max-width: 820px) {
  .match-page-home-team-container {
    grid-column: 1/2;
    grid-row: 1/2;
  }

  .match-page-away-team-container {
    grid-column: 1/2;
    grid-row: 2/3;
  }
}
