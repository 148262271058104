.field-background {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  border: 2px solid white;
}

.field-parts {
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 820px) {
  .field-parts {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(16, 1fr);
  }
}

.field-part:nth-child(even) {
  background-color: rgb(9, 150, 9);
}
.field-part:nth-child(odd) {
  background-color: green;
}

div[class^="corner"] {
  position: absolute;
  width: 3.2rem;
  height: 3.2rem;
  border: 2px solid white;
  border-radius: 50%;
}

.corner-upper-left {
  left: 0;
  top: 0;
  transform: translate(-50%, -50%);
}
.corner-upper-right {
  right: 0;
  top: 0;
  transform: translate(50%, -50%);
}

.corner-lower-left {
  left: 0;
  bottom: 0;
  transform: translate(-50%, 50%);
}

.corner-lower-right {
  right: 0;
  bottom: 0;
  transform: translate(50%, 50%);
}

.center-circle,
.center-line,
.center-dot {
  position: absolute;
}

.center-circle,
.center-dot {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.center-circle {
  width: 13.2rem;
  height: 13.2rem;
  border: 2px solid white;
  border-radius: 50%;
}

.center-line {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
  height: 100%;
  background-color: white;
}

@media screen and (max-width: 820px) {
  .center-line {
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 2px;
    width: 100%;
  }
}

.center-dot {
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;
  background-color: white;
}

.left-goal-front,
.left-penalty-area {
  position: absolute;
  top: 50%;
  left: -2px;
  transform: translateY(-50%);
  border: 2px solid white;
}

.right-goal-front,
.right-penalty-area {
  position: absolute;
  top: 50%;
  right: -2px;
  transform: translateY(-50%);
  border: 2px solid white;
}

.left-goal-front,
.right-goal-front {
  width: 12.8rem;
  height: 22.5rem;
}

.left-penalty-area,
.right-penalty-area {
  width: 5rem;
  height: 10rem;
}

@media screen and (max-width: 820px) {
  .left-goal-front,
  .left-penalty-area {
    position: absolute;
    top: -2px;
    left: 50%;
    transform: translateX(-50%);
  }
  .right-goal-front,
  .right-penalty-area {
    position: absolute;
    bottom: -2px;
    top: auto;
    left: 50%;
    transform: translateX(-50%);
  }
  .left-goal-front,
  .right-goal-front {
    height: 12.8rem;
    width: 22.5rem;
  }

  .left-penalty-area,
  .right-penalty-area {
    height: 5rem;
    width: 10rem;
  }
}
